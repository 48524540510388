<script>
import { getShopList, issuedGoodsData, issuedShopProgress } from "api/issued";
import IssuedProgress from "@/pages/operationCenter/componnets/IssuedProgress/IssuedProgress.vue";
import SelectGoodsPopup from "components/SelectGoodsPopup.vue";

export default {
  name: "GoodsIssued",
  components: { SelectGoodsPopup, IssuedProgress },
  data: function() {
    return {
      setIntervalInstance: undefined,
      leftSearchInput: "",
      rightSearchInput: "",
      goodsPopupShopNo: "",
      targetShopList: [],
      targetShopStr: "",
      spuInfoList: [],
      requestStatusObj: { loading: false },
      table: {
        shopList: [],
        shopNoObj: {}
      },
      issuedProgressParams: {
        percentage: 0,
        active: 0,
        successNum: 0,
        failNum: 0
      }
    };
  },
  created() {
    getShopList({
      params: {
        merCode: this.$store.getters.LoginInfo.merCode,
        // merchantNo: this.$store.getters.LoginInfo.merCode,
        merType: this.$store.getters.LoginInfo.merType
      }
    }).then(res => {
      this.table.shopList = res.data;
      res.data.forEach(value => {
        this.table.shopNoObj[value.shopNo] = value;
      });
    });
  },
  methods: {
    selectTargetShop: function(event, data) {
      if (event) {
        this.targetShopList.push(data);
      } else {
        this.targetShopList.splice(this.targetShopList.indexOf(data), 1);
      }
      this.targetShopStr = this.targetShopList.map(value => value.shopName).join(",");
    },
    selectGoods: function() {
      if (!this.goodsPopupShopNo) {
        this.$message.warning("请选择店铺");
        return;
      }
      this.$refs.selectGoodsPopupRef.open();
    },
    issued: function() {
      if (!this.spuInfoList.length) {
        this.$message.warning("请选择商品");
        return;
      }
      if (!this.targetShopList.length) {
        this.$message.warning("请选择目标店铺");
        return;
      }

      this.$refs.IssuedProgressRef.open();
      this.issuedProgressParams.active = 1;
      this.issuedProgressParams.successNum = 0;
      this.issuedProgressParams.failNum = 0;
      this.issuedProgressParams.percentage = 0;
      console.log("发送数据开始", new Date());
      issuedGoodsData({
        params: {
          "spuIdList": this.spuInfoList.map(value => value.id),
          "targetShopNoList": this.targetShopList.map(value => value.shopNo)
        }
      }).then(res => {
        this.issuedProgressParams.active = 2;
        this.setIntervalInstance = setInterval(() => {
          this.queryIssuedProgress(res.data);
        }, 1000);
      });
    },
    queryIssuedProgress(key) {
      issuedShopProgress(key).then(res => {
        // console.log(res.data);
        const data = res.data;
        this.issuedProgressParams.percentage = data.rate;
        this.issuedProgressParams.successNum = data.successCount;
        this.issuedProgressParams.failNum = data.errCount;
        if (data.rate === 100) {
          console.log("发送数据结束", new Date());
          this.issuedProgressParams.active = 3;
          clearInterval(this.setIntervalInstance);
        }
      });
    },
    issuedProgressClosed: function() {
      console.log("issuedProgressClosed");
      clearInterval(this.setIntervalInstance);
    }
  }
};
</script>

<template>
  <div class="container">
    <div class="container_cards">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <el-input placeholder="请输入内容" v-model="leftSearchInput" class="input-with-select">
            <el-select :value="'商品名称'" slot="prepend" placeholder="请选择">
              <el-option label="商品名称" value="1"></el-option>
            </el-select>
          </el-input>
          <el-button class="clearfix_button" type="primary">搜索</el-button>
        </div>
        <div>
          <el-select class="shopSelect" v-model="goodsPopupShopNo" filterable placeholder="请选择">
            <el-option
              v-for="item in table.shopList"
              :key="item.shopNo"
              :label="item.shopName"
              :value="item.shopNo">
            </el-option>
          </el-select>
          <el-button class="clearfix_button" type="primary" @click="selectGoods">选择商品</el-button>
          <el-button class="clearfix_button" type="danger" @click="spuInfoList = []">清除商品</el-button>
        </div>
        <div class="customElTableContainer">
          <el-table
            v-loading="requestStatusObj.loading"
            :data="spuInfoList.filter(data => !leftSearchInput || data.spuName.toLowerCase().includes(leftSearchInput.toLowerCase()))"
            style="width: 100%"
            height="calc(100vh - 433px)"
          >
            <el-table-column type="index" label="序号" />
            <el-table-column prop="spuName" label="名称" align="center" />
            <el-table-column prop="unitType" label="单位" align="center" />
            <el-table-column align="center" fixed="right" width="210" label="操作">
              <template slot-scope="{ row,index }">
                <el-button
                  class="textBlue"
                  type="primary"
                  style="margin-right: 10px"
                  @click="spuInfoList.splice(index,1)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <div style="width: 10px;flex: none"></div>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <el-input placeholder="请输入内容" v-model="rightSearchInput" class="input-with-select">
            <el-select :value="'店铺名称'" slot="prepend" placeholder="请选择">
              <el-option label="店铺名称" value="1"></el-option>
            </el-select>
          </el-input>
          <el-button class="clearfix_button" type="primary">搜索</el-button>
        </div>

        <div class="customElTableContainer">
          <el-table
            v-loading="requestStatusObj.loading"
            :data="table.shopList.filter(data => !rightSearchInput || data.shopName.toLowerCase().includes(rightSearchInput.toLowerCase()))"
            style="width: 100%"
            row-key="shopNo"
            :reserve-selection="true"
            height="calc(100vh - 383px)"
          >
            <!--            @selection-change="selectTargetShop">-->
            <!--            <el-table-column type="selection" width="55"></el-table-column>-->
            <el-table-column type="index" label="序号" />
            <el-table-column align="center" prop="shopName" label="门店名称" />
            <el-table-column align="center" fixed="right" width="210" label="操作">
              <template slot-scope="{ row }">
                <!--                <el-button-->
                <!--                  type="primary"-->
                <!--                  style="margin-right: 10px"-->
                <!--                  @click="selectTargetShop(row)"-->
                <!--                >-->
                <!--                  选中-->
                <!--                </el-button>-->
                <el-checkbox @change="selectTargetShop($event,row)"></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
    <div class="footer">
      <div class="footer_content">
        <span>
<!--          数据来源店铺：-->
          <!--          <span style="color: red">-->
          <!--            {{-->
          <!--              goodsPopupShopNo.shopName ? goodsPopupShopNo.shopName : "请选择"-->
          <!--            }}-->
          <!--          </span>-->
          商品总数：{{
            spuInfoList.length
          }}
        </span>
        <!--        <i class="el-icon-d-arrow-right"></i>-->
        <!--        <span>下发目标店铺：-->
        <!--          <span style="color: red">-->
        <!--            {{-->
        <!--              targetShopStr ? targetShopStr : "请选择"-->
        <!--            }}-->
        <!--          </span>-->
        <!--        </span>-->
      </div>
      <div class="footer_buttons">
        <el-button type="primary" @click="issued">开始下发</el-button>
      </div>
    </div>
    <IssuedProgress
      ref="IssuedProgressRef"
      :percentage="issuedProgressParams.percentage"
      :active="issuedProgressParams.active"
      :fail-num="issuedProgressParams.failNum"
      :success-num="issuedProgressParams.successNum"
      @closed="issuedProgressClosed"
    >
    </IssuedProgress>
    <select-goods-popup
      ref="selectGoodsPopupRef"
      :selected-item="spuInfoList"
      @selectedTableValue="
        (value) => {
          spuInfoList = value;
        }
      "
      :shop-no=goodsPopupShopNo
    >
    </select-goods-popup>
  </div>
</template>

<style scoped lang="less">
.container {

  &_cards {
    display: flex;
  }

  .box-card {
    height: calc(100vh - 271px);
    flex: 1;
  }

  .clearfix {
    display: flex;
    align-items: center;
  }

  .clearfix_button {
    margin-left: 12px;
  }

  .footer {
    background: #FFFFFF;
    margin-top: 10px;

    i {
      margin: 0 20px;
    }

    &_content {
      text-align: center;
      font-size: 16px;
      padding-top: 20px;
    }

    &_buttons {
      text-align: center;
      padding: 20px 0;
    }
  }
}

</style>


<style>
.shopSelect {
  padding-bottom: 10px;
}

.shopSelect.el-select .el-input {
  width: 210px;
}

.el-select .el-input {
  width: 110px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.input-with-select {
  width: 300px;
}

</style>
