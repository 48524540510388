<template>
  <div>
    <base1-dialog ref="baseDialogRef" width="800px" class="addPurchase_dialog" title="查询商品">
      <div class="addPurchase_dialog_header">
        <div style="display: flex;justify-content: space-between;flex: 1;align-items: center;">
          <div class="addPurchase_dialog_header_search" style="display: flex;align-items: center">
            <!--            <el-input-->
            <!--              v-model="dialogData.getGoodsParams.keyword"-->
            <!--              placeholder="请输入商品名称"-->
            <!--              class="addPurchase_dialog_header_input"-->
            <!--              @change="resetGetDialogTabData"-->
            <!--            >-->
            <el-input
              v-model="searchData.keyword"
              placeholder="请输入名称"
            >
              <el-button
                slot="append"
                :disabled="requestStatusObj.loading"
                icon="el-icon-search"
                @click="resetPageGetDialogTabData"
              />
            </el-input>
            <el-button
              :disabled="requestStatusObj.loading"
              class="default"
              style="margin-left:10px;height: 33px;min-width: 60px"
              @click="resetFilter"
            >
              重置
            </el-button>
          </div>
          <div style="display:flex;align-items: center" />
        </div>
      </div>
      <div class="addPurchase_dialog_content">
        <div class="addPurchase_dialog_content_right">
          <div class="customElTableContainer addPurchase_dialog_table">
            <el-table
              ref="multipleTable"
              v-loading="requestStatusObj.loading"
              :data="dialogData.tableData"
              height="calc(100vh - 350px)"
              @selection-change="handleDialogSelectionChange"
            >
              <el-table-column type="selection" align="center" />
              <el-table-column prop="spuName" label="名称" align="center" />
              <el-table-column prop="unitType" label="单位" align="center" />
              <el-table-column prop="createTime" label="创建时间" align="center" />
            </el-table>
          </div>
          <div class="addPurchase_dialog_content_pagination">
            <div class="addPurchase_dialog_content_pagination_left">
              已选 {{ dialogData.multipleSelection.length }} 条
            </div>
            <el-pagination
              background
              :current-page.sync="pagingData.currentPage"
              :page-sizes="[10, 25, 40]"
              :page-size.sync="pagingData.pageSize"
              :pager-count="5"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagingData.pageTotal"
              @size-change="handlePagingDataSizeChange"
              @current-change="handlePagingDataCurrentChange"
            />
          </div>
        </div>
      </div>
      <div class="addPurchase_dialog_bottom">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </div>
    </base1-dialog>
  </div>
</template>

<script>
import Base1Dialog from "@/components/BaseDialog/Base1Dialog.vue";
import { queryGoodsList } from "api/issued";

export default {
  name: "SelectGoodsPopup",
  components: { Base1Dialog },
  data: function() {
    return {
      requestStatusObj: {
        loading: false
      },
      filePath: this.$store.getters["dict/get_file_path"],
      dialogData: {
        tableData: [],
        multipleSelection: []
      },
      pagingData: {
        pageSize: 10,
        currentPage: 1,
        pageTotal: 0
      },
      searchData: {
        // keywordKey: 'memberName',
        keyword: ""
        // placeholder: {
        //   'memberName': '请输入会员名称',
        //   'telephone': '请输入手机号',
        //   'cardNo': '请输入会员卡号'
        // },
        // searchSelectOption: [
        //   {
        //     value: 'memberName',
        //     label: '会员名称'
        //   }, {
        //     value: 'telephone',
        //     label: '手机号码'
        //   }, {
        //     value: 'cardNo',
        //     label: '会员卡号'
        //   }
        // ]
      },
      selectedItemObj: {}
    };
  },
  props: {
    selectedItem: {
      default: function() {
        return [];
      }
    },
    goodsIdKey: {
      default: "id"
    },
    shopNo: {
      required: true,
      type: String
    }
  },
  methods: {
    getDialogData: function() {
    },
    getDialogTabData: function() {
      const params = {
        "shopNo": this.shopNo,
        "page": this.pagingData.currentPage,
        "limit": this.pagingData.pageSize,
        "spuName": this.searchData.keyword
      };
      console.log(this.requestStatusObj);
      this.requestStatusObj.loading = true;
      queryGoodsList({ params: params })
        .then(
          (res) => {
            console.log(this.requestStatusObj);
            this.dialogData.tableData = res.data.records;
            this.pagingData.pageTotal = res.data.total;
            this.toggleSelection();
          }
        )
        .finally(() => this.requestStatusObj.loading = false);
    },
    resetPageGetDialogTabData: function() {
      // this.dialogData.multipleSelection = []
      this.pagingData.currentPage = 1;
      this.getDialogTabData();
    },
    resetFilter: function() {
      this.searchData.keyword = "";
      // this.dialogData.multipleSelection = []
      this.pagingData.currentPage = 1;
      this.getDialogTabData();
    },
    handleDialogSelectionChange: function(val) {
      this.dialogData.multipleSelection = val;
    },
    toggleSelection: function() {
      const selectedTableList = this.dialogData.multipleSelection;
      const tableData = this.dialogData.tableData;

      if (tableData && selectedTableList.length !== 0) {
        for (let j = 0; j < selectedTableList.length; j++) {
          for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].id === selectedTableList[j].id) {
              selectedTableList[j] = tableData[i];
              break;
            }
          }
        }
      }
      this.$nextTick(() => {
        selectedTableList.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      });
    },
    handlePagingDataSizeChange: function(event) {
      this.pagingData.pageSize = event;
      this.pagingData.currentPage = 1;
      this.getDialogTabData();
    },
    handlePagingDataCurrentChange: function(event) {
      this.getDialogTabData();
    },
    closeDialog: function() {
      this.$refs.baseDialogRef.close();
    },
    open: function() {
      this.dialogData.multipleSelection = [];
      this.dialogData.tableData = [];
      this.pagingData.currentPage = 1;
      this.pagingData.pageSize = 10;

      // 回显勾选项
      this.selectedItemObj = {};
      this.$nextTick(() => {
        this.selectedItem.forEach(value => {
          this.selectedItemObj[value[this.goodsIdKey]] = value;
          this.dialogData.multipleSelection.push({ id: value[this.goodsIdKey] });
        });
      });

      this.getDialogData();
      this.getDialogTabData();
      this.$refs.baseDialogRef.open();
    },
    submit: function() {
      // 将传入的数据覆盖掉接口的数据
      const selectedData = [];
      this.dialogData.multipleSelection.forEach(value => {
        this.selectedItemObj[value.id] ? selectedData.push(this.selectedItemObj[value.id]) : selectedData.push(value);
      });

      this.$emit("selectedTableValue", selectedData);
      this.closeDialog();
    }
  }
};
</script>

<style scoped lang="less">
@import '../css/button.less';

@leftWidth: 126px;


.addPurchase_dialog {
  &_header {
    display: flex;
    justify-content: space-between;

    &_input {
      padding-left: 8px;
      //width: 426px;

      ::v-deep input {
        font-size: 14px;
        background: #F6F7F8;
        font-weight: 400;
        //color: #999999;
        border: none;
      }

      ::v-deep .el-input-group__append {
        border-top: 0px;
        border-bottom: 0px;
        border-right: 0px;
      }
    }

    &_select {
      width: @leftWidth;
      height: 34px;
      background: #FFFFFF;
      flex: none;

      ::v-deep input {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        border-radius: 4px;
        border: 1px solid #E9E9E9;
      }
    }
  }

  &_content {
    padding-top: 10px;
    display: flex;

    &_left {
      flex: none;
      width: @leftWidth;
      margin-right: 8px;
      overflow-y: auto;

      ::v-deep .el-radio-group {

        display: flex;
        flex-direction: column;
        border-left: 1px solid #EBEBEB;
        border-top: 1px solid #EBEBEB;
        box-sizing: border-box;
        width: auto;

        .el-radio-button {
          width: auto;
        }

        .el-radio-button:first-child {
          .el-radio-button__inner {
            border-radius: 0;
          }
        }

        .el-radio-button:last-child {
          .el-radio-button__inner {
            border-radius: 0;
          }
        }

        .el-radio-button__inner {
          border-right: 1px solid #EBEBEB;
          border-bottom: 1px solid #EBEBEB;
          border-top: 0;
          border-left: 0;
          width: 100%;
          height: 40px;
          padding: 0;
          line-height: 40px;
        }
      }
    }

    &_right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex: 1;
    }

    &_pagination {
      padding-top: 18px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
  }

  &_bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 20px;
  }

  &_table {
    //width: calc(90vw - 3.2vw - 134px);
    width: calc(800px - 3.2vw);

    ::v-deep .el-table--medium {
    }
  }
}
</style>
